@import "../variables"

.drank-vd-maand h3
  margin-top: 2rem

.drank-vd-maand img
  max-height: 40rem
  max-width: 100%

.drank-vd-maand-text
  background: #FAFAFA
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25)
  padding: 2rem
  height: 25rem
  display: flex
  flex-direction: column
  justify-content: center

.drank-vd-maand-text p
  margin-top: 1rem
  font-size: 1.2rem
  color: #666

@media only screen and (max-width: 480px)
  .drank-vd-maand-text p
    padding: 0 1rem 2rem 1rem
    margin-top: 0

  .drank-vd-maand-text h4
    padding-top: 2rem

  .drank-vd-maand-text
    height: 30rem
