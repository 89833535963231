.error-container
  height: 2rem

.error-container p
  padding: .5rem
  color: red
  font-size: 1rem

.form .text-container
  margin-top: 1rem
  min-height: 50px
  width: 100%
  border-bottom: 1px dashed #e0e0e1
  position: relative

.form p
  font-size: 1rem

.form .text-container input, .form .text-container div
  color: #0C0C0C
  height: 100%
  width: 100%
  border: 0
  padding: 20px 20px 0 20px
  float: left
  position: relative
  background-color: transparent
  font-size: 1rem

.form .text-container div
  cursor: pointer

.form .text-container > span
  position: absolute
  top: 50%
  padding-top: 10px
  left: 20px
  -webkit-transform: translateY(-52%)
  -ms-transform: translateY(-52%)
  transform: translateY(-52%)
  font-weight: 300
  font-size: 1rem
  color: #777
  -webkit-transition: all .2s ease-in-out
  transition: all .2s ease-in-out
  z-index: -1

.form .text-container > span.active
  color: #000
  font-size: .8rem
  top: 5px
  left: 5px
  padding: 5px

.form .form-btn
  color: #777
  background-color: transparent
  border: none
  outline: none
  position: relative
  display: inline-block
  -webkit-transition: all .3s ease-in-out
  transition: all .3s ease-in-out
  padding: 0 0 50px 0
  font-size: 14px
  letter-spacing: 1px
  font-weight: 400

.form .form-btn > svg
  opacity: 0
  position: absolute
  font-size: 25px
  -webkit-transition: all .3s ease-in-out
  transition: all .3s ease-in-out
  right: 30px
  top: 0
  color: #ccc

.form .form-btn:hover > svg
  opacity: 1
  right: -30px

.form .form-btn:hover:after
  width: 100%

.form .form-btn:after
  content: ""
  position: absolute
  top: 27px
  left: 0
  width: 50%
  height: 2px
  background-color: #ccc
  -webkit-transition: all .3s ease-in-out
  transition: all .3s ease-in-out

.form .form-btn:hover
  color: #0C0C0C
  cursor: pointer

.form .info
  font-size: 1rem
  margin-bottom: 1rem

