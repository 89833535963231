@import "../variables"

.features
  padding: 2.5rem 0
  background-color: $grayColor
  border-top: 15px double #fff

@media only screen and (max-width: 480px)
  .features
    padding: 5rem 0 5rem 0

  .features .feature-item:not(:first-of-type)
    margin-top: 2.5rem

.features .feature-item .item-head
  position: relative
  display: block


.features .feature-item .item-head svg
  font-size: 5rem
  color: #000000


.features .feature-item h6
  text-transform: uppercase
  margin-top: 1rem
  font-size: .9rem
  margin-bottom: 0
  padding: 0
  color: #000000
  font-weight: 700
  letter-spacing: .1rem


.features .feature-item p
  margin-bottom: 0
  padding: 0
  font-size: 1.2rem
  color: #FFFFFF
