@import "variables"

/* basics */

html
  overflow-y: scroll
  font-size: 100%

*
  outline: 0
  padding: 0
  margin: 0
  -webkit-box-sizing: border-box
  box-sizing: border-box

body
  background: #ffffff
  font: 400 14px/1.8 $cantarell
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden

.container-fluid
  padding: 0

h1, h2, h3, h4, h5, h6
  color: #000
  text-transform: uppercase
  font-family: 'Cantarell', sans-serif


a:hover:after
  width: 50%

a:hover, a:focus
  color: #000
  text-decoration: none

ol, ul
  list-style: none

p
  font-weight: 300
  margin-bottom: 0
  color: #000
  font-size: 1.4rem
  text-transform: none
  text-align: left

/* visual effects */

.image-background
  background-image: url('../img/odt.png')
  height: 100%
  background-attachment: fixed !important
  background-position: center
  background-repeat: no-repeat !important
  background-size: cover !important

@media only screen and (max-width: 480px)
  .image-background
    background-attachment: scroll !important

.fill-left
  width: 0
  height: 0
  border-top: 100px solid $grayColor
  border-right: 100vw solid transparent


/* buttons */

.button
  padding: 16px 42px
  box-shadow: 0 0 12px -2px rgba(0,0,0,0.5)
  line-height: 1.25
  background: $grayColor
  text-decoration: none
  font-weight: 300
  color: white
  font-size: 16px
  letter-spacing: .08em
  text-transform: uppercase
  position: relative
  transition: background-color .6s ease
  overflow: hidden
  &:after
    content: ""
    position: absolute
    width: 0
    height: 0
    top: 50%
    left: 50%
    transform-style: flat
    transform: translate3d(-50%,-50%,0)
    background: rgba(white,.1)
    border-radius: 100%
    transition: width .3s ease, height .3s ease
  &:focus,
  &:hover
    background: darken($grayColor,7%)
  &:active
    &:after
      width: 200px
      height: 200px

.default-btn
  position: relative
  display: inline-block
  -webkit-transition: all .3s ease-in-out
  transition: all .3s ease-in-out
  padding: 0 0 50px 0
  font-size: 14px
  letter-spacing: 1px
  margin-top: 70px
  font-weight: 400

.default-btn svg
  opacity: 0
  position: absolute
  font-size: 25px
  -webkit-transition: all .3s ease-in-out
  transition: all .3s ease-in-out
  right: 30px
  top: 0
  color: #ccc

.gray-btn
  color: #777 !important
  text-transform: uppercase
  font-weight: 400
  font-size: 12px !important

.default-btn:hover svg
  opacity: 1
  right: -30px

.default-btn:hover:after
  width: 100%

.default-btn:after
  content: ""
  position: absolute
  top: 27px
  left: 0
  width: 50%
  height: 2px
  background-color: #ccc
  -webkit-transition: all .3s ease-in-out
  transition: all .3s ease-in-out

/* inputs */

.input-1
  height: 50px
  width: 100%
  border-bottom: 1px dashed #e0e0e1
  position: relative

.input-1 input
  height: 100%
  width: 100%
  border: 0
  padding: 0 20px
  float: left
  position: relative
  background-color: transparent
  font-size: 1rem
  color: #9a9a9a
  z-index: 1

.input-1 > span
  position: absolute
  top: 50%
  left: 20px
  z-index: 0
  -webkit-transform: translateY(-52%)
  -ms-transform: translateY(-52%)
  transform: translateY(-52%)
  font-weight: 300
  font-size: 1rem
  color: #777
  -webkit-transition: all .2s ease-in-out
  transition: all .2s ease-in-out

.input-1 > span.active, .textarea_1 > span.active
  color: #000
  font-size: .8rem
  top: 5px
  left: 5px
  padding: 5px

.input-3 > span.active, .textarea_1 > span.active
  color: #721c24

.steven-jambon
  height: 12px
  margin-bottom: 4px
  fill: #777777
  transition: all .4s ease
  -webkit-transition: all .4s ease

.steven-jambon:hover
  fill: #cccccc
  cursor: pointer
