@import "../variables"

.menu
  margin-top: 2rem

.menu h3
  font-size: 2rem

.menu h4
  font-size: 1.5rem

.menu p
  font-size: 1.1rem

@media only screen and (max-width: 480px)
  .menu
    margin-top: 0
    margin-bottom: 1rem
