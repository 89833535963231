@import "../variables"

.testimonials
  padding: 100px 0 100px
  color: #fff

.testimonials h5
  font-size: 30px
  font-family: $cantarell
  margin: 30px 0
  text-transform: none
  text-align: center
  color: #FFFFFF

.testimonials p
  color: #d8d8d8
  letter-spacing: 2px
  text-align: center

.swiper-button-next, .swiper-button-prev
  color: #f9f9f9 !important

@media only screen and (max-width: 768px)
  #testimonials
    padding: 200px 0 200px
    color: #fff

@media only screen and (max-width: 480px)
  #testimonials
    margin: 0 auto
    padding: 10rem 0

  .swiper-button-next, .swiper-button-prev
    display: none !important
