@import "../variables"

.evenement
  margin-top: 1rem

.evenementen-text
  text-align: left
  margin-bottom: 2rem

.evenement_container img
  width: 100%

.evenement .categories-grid .categories ul li
  list-style: none
  margin: 1rem 0


.evenement .categories-grid .categories ul li p
  display: inline-block
  text-transform: uppercase
  color: #777
  margin-left: 1rem
  font-size: .9rem
  font-weight: 400

.evenement .categories-grid .categories ul li p:hover
  cursor: pointer

.evenement .categories-grid .categories ul li p.active
  margin-left: 0
  padding: 0 .4rem
  font-size: 1rem
  color: #000
  font-weight: 700
  transition: all .5s ease

@media (max-width: 992px)

  .evenement .categories-grid .categories ul li
    text-align: center

  .evenement .categories-grid .categories ul li p
    margin-left: 0

.evenement_item
  position: relative
  overflow: hidden
  display: inline-block

.evenement_item .evenement_item_hover
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  background-color: #fff
  -webkit-transform: translateX(-100%)
  -ms-transform: translateX(-100%)
  transform: translateX(-100%)
  opacity: 0
  -webkit-transition: all .2s ease-in-out
  transition: all .2s ease-in-out

.evenement_item .evenement_item_hover .item_info
  text-align: center
  position: absolute
  top: 50%
  left: 50%
  -webkit-transform: translate(-50%, -50%)
  -ms-transform: translate(-50%, -50%)
  transform: translate(-50%, -50%)

.evenement_item .evenement_item_hover .item_info span
  display: inline-block
  font-family: $cantarell
  font-weight: 400
  text-transform: uppercase
  color: #000
  font-size: 1.2rem
  -webkit-transform: translateY(20px)
  -ms-transform: translateY(20px)
  transform: translateY(20px)
  -webkit-transition: all .5s ease-in-out .3s
  transition: all .5s ease-in-out .3s
  opacity: 0

.evenement_item .evenement_item_hover .item_info em
  font-weight: 400
  display: inline-block
  background-color: #000
  padding: .5rem
  color: #8e8e8e
  margin-top: .5rem
  -webkit-transform: translateY(1.5rem)
  -ms-transform: translateY(1.5rem)
  transform: translateY(1.5rem)
  -webkit-transition: all .5s ease-in-out .5s
  transition: all .5s ease-in-out .5s
  opacity: 0

.evenement_item:hover .evenement_item_hover
  opacity: 1
  -webkit-transform: translateY(0)
  -ms-transform: translateY(0)
  transform: translateY(0)

.evenement_item:hover .item_info em, .evenement_item:hover .item_info span
  opacity: 1
  -webkit-transform: translateY(0)
  -ms-transform: translateY(0)
  transform: translateY(0)

.my-masonry-grid
  display: flex
  margin-left: -1.8rem
  width: auto

.my-masonry-grid_column
  padding-left: 1.8rem
  background-clip: padding-box

.my-masonry-grid_column > div
  margin-bottom: 1.8rem
