@import url(https://fonts.googleapis.com/css?family=Lato:400,900,300)
@import url(https://fonts.googleapis.com/css?family=Cantarell:400,700)

$cantarell: 'Cantarell', sans-serif
$lato: 'Lato', serifs

$grayColor: #8e8e8e
$facebook : #3b5998
$instagram : #d6249f

/* brands */

.facebook:hover
  color: $facebook !important
  transition: all .5s ease

.instagram:hover
  color: $instagram !important
  transition: all .5s ease
