@import "../variables"

.assortiment
  background-color: $grayColor
  padding-bottom: 3rem
  border-bottom: 15px double #fff

.assortiment h5
  font-size: 1.8rem
  font-weight: 500
  margin-top: 2rem
  margin-bottom: 2rem
  color: #f9f9f9
