@import "../variables"

.contact-page h2
  margin-top: 2rem

.contact-page p
  margin: 1rem 0
  font-size: 1.2rem
  text-align: center

.detail h3
  color: #000
  font-weight: 700
  font-size: 1.2rem
  text-transform: uppercase

.detail h4
  letter-spacing: .1rem
  color: #777
  font-weight: 300
  font-size: 1.2rem

.detail p
  color: #777
  font-size: 1rem
  margin-bottom: 1rem
  text-align: center
  font-weight: 300
  line-height: 2

.detail a:first-of-type
  margin-top: 1rem

.detail a
  color: #777
  font-size: 1rem
  padding-left: 1rem
  padding-right: 1rem
  text-align: center
  font-weight: 300
  line-height: 2

.detail a:hover
  transition: all .5s ease
  color: #000000

.detail svg
  color: #777
  margin: 2rem 0 1rem
  font-size: 3rem

.detail .email
  display: flex
  flex-direction: column

.social-links
  max-width: 15rem
  margin: auto
  display: flex
  flex-direction: row
  justify-content: center

.social-links .link-container
  display: flex
  justify-content: center

.social-links .link svg
  margin: 1rem auto 0
  transition: all .5s ease

.social-links .link p
  margin: 0
  transition: all .5s ease

.social-links .link:hover.facebook p
  color: $facebook

.social-links .link:hover.facebook svg
  color: $facebook

.social-links .link:hover.instagram svg
  color: $instagram

.social-links .link:hover.instagram p
  color: $instagram
