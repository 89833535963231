/* navbar */

.navbar
  position: fixed
  top: 0
  left: 0
  height: 50px
  width: 100%
  z-index: 1000
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.navbar
  height: 6rem
  box-shadow: none
  background: #FFFFFF
  top: 0
  padding: 0

.navbar.is-fixed
  position: fixed
  top: -6rem

.navbar-background
  display: block
  padding: 0 0 0 30px
  height: 6rem

#header-logo
  max-height: 100%
  height: 96px
  width: 96px
  padding: .75rem

.hamburger-menu
  position: absolute
  right: 40px
  height: 30px
  padding-left: 1em
  background-color: transparent
  top: 40%
  bottom: auto
  z-index: 1000
  -webkit-transform: translateY(-50%)
  transform: translateY(-50%)

.navigation
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, 0.95)
  position: fixed
  top: 0
  left: 0
  transition-property: all
  transition-duration: .5s
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  overflow: scroll
  z-index: 100

.navigation.hide
  max-height: 0
  overflow: hidden

.navigation.show
  height: 100%
  overflow: hidden

.navigation-items
  margin: 12rem auto
  width: 100%
  text-align: center

@media only screen and (max-width: 480px)
  .navigation-items
    margin: 8rem auto

.navigation-items li
  font-size: 1.4rem
  font-weight: 300
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  margin: .2em 0
  text-transform: capitalize

.navigation-items a
  display: inline-block
  padding: .4em 1em
  border-radius: 0.25em
  -webkit-transition: all 0.2s
  transition: all 0.2s
  color: #fff
  text-decoration: none
  letter-spacing: .1rem

.navigation-items a:focus
  outline: none

//.navigation-items a:hover
//  text-decoration: none
//  color: #808080

.navigation-items .box-label
  color: #808080
  text-transform: uppercase
  font-size: 1.3rem
  margin: 0 0 .8em

.navigation-items .box-social
  display: inline-block
  margin: .8rem .4rem

/* header large */

.box-intro
  text-align: center
  display: table
  height: 100vh
  width: 100%
  background-image: url('../img/odt.png')
  background-attachment: fixed
  background-position: center center
  -webkit-background-size: cover
  -moz-background-size: cover
  -o-background-size: cover
  background-size: cover

@media only screen and (max-width: 480px)
  .box-intro
    background-attachment: scroll !important

.bg-film:before
  background: url('../img/dot.gif')
  opacity: 0.03
  content: ""
  left: 0
  top: 0
  position: absolute
  height: 100%
  width: 100%
  z-index: 1

.box-intro .table-cell
  display: table-cell
  vertical-align: middle


.box-intro em
  font-style: normal
  text-transform: uppercase


.box-intro h1
  font-size: 40px
  margin-top: 35px
  color: #ffffff
  letter-spacing: 40px
  font-weight: 700


/* mouse effect */

.mouse
  position: absolute
  width: 20px
  height: 35px
  bottom: 5rem
  left: 50%
  margin-left: -12px
  border-radius: 15px
  border: 1px solid #FFFFFF
  -webkit-animation: intro 1s
  animation: intro 1s


.scroll
  display: block
  width: 1px
  height: 5px
  margin: 6px auto
  border-radius: 4px
  background: #FFFFFF
  -webkit-animation: finger 2s infinite
  animation: finger 2s infinite


@keyframes intro
  0%
    opacity: 0
    -webkit-transform: translateY(40px)
    transform: translateY(40px)
  100%
    opacity: 1
    -webkit-transform: translateY(0)
    transform: translateY(0)

@-webkit-keyframes finger
  0%
    opacity: 1
  100%
    opacity: 0
    -webkit-transform: translateY(20px)
    transform: translateY(20px)

@keyframes finger
  0%
    opacity: 1
  100%
    opacity: 0
    -webkit-transform: translateY(20px)
    transform: translateY(20px)


@media (max-width: 992px)
  .box-intro h1
    font-size: 25px
    letter-spacing: 5px

/* header small */

.header-small
  background: url("../img/odt.png") no-repeat center
  background-size: cover
  height: 40rem
  width: 100%
  position: relative

.header-small .sign-out
  float: right
  margin-top: 8rem
  margin-right: 3rem

.header-small .sign-out button
  background-color: transparent
  border: none
  outline: none
  display: flex
  flex-direction: row

.header-small .sign-out p
  margin-right: 1rem
  margin-top: auto
  margin-bottom: auto
  color: white

.header-small .sign-out svg
  height: 1.5rem
  width: 1.5rem
  margin-top: auto
  margin-bottom: auto
  color: white

.header-small .page-title
  position: absolute
  top: 50%
  left: 50%
  -webkit-transform: translate(-50%, -50%)
  -ms-transform: translate(-50%, -50%)
  transform: translate(-50%, -50%)
  text-align: center

.header-small h1
  color: #ffffff
  text-transform: uppercase
  font-size: 1.7rem
  line-height: 2
  letter-spacing: .7rem

.header-small .small-title
  color: #ffffff
  font-weight: 700
  letter-spacing: .1rem
  font-size: .8rem
