@import "../variables"

.bestuursleden
  margin-top: 2rem

.bestuursleden p
  font-size: 1.2rem
  text-align: justify
  margin-bottom: 2rem

.bestuursleden h5
  text-align: center
  margin: 0 0 1rem
  font-size: 2rem

.bestuurslid
  margin-bottom: 1.2rem

.bestuurslid:last-of-type
  margin-bottom: 0

.bestuurslid-info
  overflow: hidden
  position: relative

.bestuurslid-info img
  max-width: 100%

.bestuurslid-info .overlay h6
  font-size: 1.2rem
  line-height: 2rem
  margin-top: 50%
  text-transform: uppercase
  color: #333
  font-weight: 700

.bestuurslid-info .overlay p
  font-size: 1rem
  text-align: center
  color: #777
  opacity: .5
  font-weight: 400

.bestuurslid-info .overlay
  padding-top: 6rem

.bestuurslid h6
  font-size: 1rem
  font-family: $cantarell
  margin: 1rem
  text-align: center
  color: #333
  text-transform: uppercase
  font-weight: 700

@media only screen and (max-width: 480px)
  .team-member h6
    margin-top: 1.5rem

.bestuurslid-info:hover .overlay
  opacity: 0.8
  -webkit-transform: rotateY(0deg) scale(1, 1)
  -moz-transform: rotateY(0deg) scale(1, 1)
  -ms-transform: rotateY(0deg) scale(1, 1)
  -o-transform: rotateY(0deg) scale(1, 1)
  transform: rotateY(0deg) scale(1, 1)


.bestuurslid-info .overlay .social-links p
  color: #666
  opacity: 1

.bestuurslid-info .overlay .social-links svg
  margin-right: .5rem

.overlay
  background-color: #fff
  text-align: center
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  padding: 7px
  opacity: 0
  transition: all 0.5s ease 0s
  -webkit-transition: all 850ms ease-in-out
  -moz-transition: all 850ms ease-in-out
  -o-transition: all 850ms ease-in-out

