@import "variables"

.contact
  padding: 100px 0 50px 0
  color: #000

.contact .bottom-right-separator
  border-color: #fff transparent transparent transparent
  bottom: -180px

.contact h4
  color: #000
  text-transform: uppercase
  font-size: 1.4rem
  letter-spacing: 10px
  line-height: 1.5

.contact a:hover
  color: #777

.contact a
  margin-top: 1rem !important
  color: #777

.contact p
  font-size: 1.2rem

.newsletter
  border-left: 1px dashed #e0e0e1
  padding-left: 50px
  width: 100%
  text-align: left

@media only screen and (max-width: 480px)
  .contact
    padding: 2rem 0 0

  .contact a
    margin-top: 10px !important

  .newsletter
    border-left: none
    padding: 2rem 0 0 0
    text-align: left
    border-top: 3px double #0C0C0C

