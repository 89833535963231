@import "../variables"

.kalender
  background-color: $grayColor
  padding-bottom: 3rem
  border-bottom: 15px double #fff

.kalender h5
  font-size: 1.8rem
  font-weight: 500
  margin: 2rem 0
  text-align: center
  color: #f9f9f9

.kalender .list-group-item
  background-color: transparent
  font-size: 1.4rem
  color: #f9f9f9

.kalender .badge
  font-size: 100%
  font-weight: normal
  padding-left: .6rem
  padding-right: .6rem
  margin-left: 1rem
