@import "variables"

footer.main-footer
  background-color: #000
  z-index: 0


footer p
  color: #777


footer a
  color: #fff
  font-family: $cantarell

footer a:hover
  color: #000
  text-decoration: none
  transition: all .5s ease

.logo-footer
  margin-bottom: 3rem
  max-height: 8rem
  filter: invert(.5)

.footer-widgets
  margin-top: 3rem

@media only screen and (max-width: 480px)
  .footer-widgets
    margin-top: 0

.footer-widget h4
  color: #777
  text-transform: uppercase
  margin-bottom: 30px
  font-size: 15px
  position: relative
  z-index: 2

.footer-widget h5
  font-size: .8rem
  text-transform: capitalize
  color: #777
  margin-bottom: 0
  margin-top: 1rem

.footer-widget a
  color: #777
  font-size: 1rem

.footer-widget a:hover
  color: #ccc
  transition: all .5s ease

.contact-widget ul.social-icons li
  display: inline-block
  margin-right: 4px
  margin-bottom: 4px

.contact-widget ul.social-icons li a i
  font-size: 15px
  width: 25px
  height: 30px
  color: #777
  display: block
  border-radius: 2px
  -webkit-border-radius: 2px
  -moz-border-radius: 2px
  -o-border-radius: 2px
  transition: all 0.2s ease-in-out
  -moz-transition: all 0.2s ease-in-out
  -webkit-transition: all 0.2s ease-in-out
  -o-transition: all 0.2s ease-in-out

.contact-widget ul.social-icons li a
  font-size: 1.4rem
  margin: .5rem

.contact-widget ul.social-icons li a:hover
  text-decoration: none
  transition: all .5s ease

.contact-widget span
  font-weight: 900

.contact-widget ul li
  margin-bottom: 12px


.contact-widget ul li p a
  color: #777


.contact-widget p
  padding-bottom: 20px

.company-links a
  color: #777


.company-links a:hover
  color: #ccc
  transition: all .5s ease


.copyright-section
  padding: 10px 0
  font-size: 15px
  margin-top: 10px
  cursor: default
  border-top: 1px solid #171717


.copyright-section p
  font-size: 15px


@media (max-width: 992px)
  footer.main-footer *
    text-align: center


  footer.main-footer ul.social-icons
    float: left
    width: 100%

  footer.main-footer nav.footer-nav ul li
    text-align: center
    width: 100%


  footer.main-footer nav.footer-nav ul li, footer.main-footer nav.footer-nav ul li:first-child
    margin-left: 0
    margin-bottom: 20px


@media only screen and (max-width: 480px)

  footer.main-footer
    padding: 0

  footer.main-footer *
    text-align: left
